<template>
  <v-lazy
    class="card flex"
    :options="{
      threshold: 0.5,
    }"
    min-height="200"
    transition="fade-transition"
  >
    <div style="height: 100%">
      <img class="card-img" :src="item.mainPhoto.url" alt="photo" />
      <div class="description flex">
        <div class="description__block">
          <div class="title__block flex">
            <a class="title">{{ item.title }}</a>
          </div>

          <p class="address mb-4">{{ item.address }}</p>
          <p class="price">
            <b class="full-price"
              ><span class="full-price">от {{ item.currency }}</span
              >{{ item.priceFrom }}</b
            >
            <span class="full-price"> до {{ item.currency }}</span
            >{{ item.priceTo }}
          </p>
          <p class="price">
            <b class="full-price"
              ><span class="full-price">от </span>{{ item.squareFrom }} м²</b
            >
            <span class="full-price"> до </span>{{ item.squareTo }} м²
          </p>
          <div class="characters-block flex justify-center mt-4">
            <div
              :class="`${
                isMap ? 'characters-block__items__map' : ''
              } characters-block__items justify-center text-center pa-3`"
            >
              {{ item.items.length }} объектов
            </div>
          </div>
        </div>
        <!--<div class="id-block flex">
          <b class="ident">ID: {{ item.id }}</b>
        </div>-->
      </div>
    </div>
  </v-lazy>
</template>

<script>
export default {
  name: "Card-Component",
  props: {
    item: Object,
    modelValue: Boolean,
    isMap: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      selected: false,
    };
  },
  watch: {
    selected(val) {
      this.$emit("update:modelValue", val);
    },
  },
};
</script>

<style scoped>
.card {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
}
.card__block {
  flex-direction: column;
}
.card-img {
  width: 100%;
  height: 50%;
  border-radius: 20px;
  object-fit: cover;
}
.description {
  flex-direction: column;
  padding: 12px;
  height: 50%;
}
.description__basic {
  height: 90%;
}
.characters-block__items {
  width: 70%;
  border: 1px solid #f4f6f9;
  border-radius: 35px;
  background-color: #f4f6f9;
}
.characters-block__items__map {
  margin-bottom: 40px;
}
.id-block {
  justify-content: end;
}
.title__block {
  justify-content: space-between;
  margin-bottom: 10px;
}
.price {
  font-size: 16px;
}
.address {
  font-size: 14px;
  font-weight: 400;
}
.characters {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.characters__text {
  margin-right: 3px;
}
.characters__icon {
  margin-right: 3px;
}
.status-block {
  flex-direction: row;
}
.item__type {
  position: absolute;
  padding: 0 4px;
  border: 1px solid #fff;
  border-radius: 6px;
  background-color: #fff;
  top: 18px;
  left: 15px;
}
.checkbox_block {
  position: absolute;
  top: 15px;
  right: 0;
}
.checkbox_label {
  padding: 0;
  margin: 0;
}
</style>
