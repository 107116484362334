<template>
  <v-card
    class="filter__container"
    tile
    v-touch="{
      right: () => swipe(),
    }"
  >
    <div class="filter__header pt-5 pb-5 pl-5">
      <v-row>
        <v-col>
          <button
            class="btn-reset close__filter"
            @click="this.$emit('update:modelValue', false)"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17 10C17 10.1989 16.921 10.3897 16.7803 10.5303C16.6397 10.671 16.4489 10.75 16.25 10.75L5.61201 10.75L9.77001 14.71C9.9064 14.8494 9.98312 15.0365 9.9839 15.2315C9.98467 15.4266 9.90943 15.6142 9.77414 15.7547C9.63885 15.8952 9.45416 15.9775 9.25923 15.9841C9.0643 15.9907 8.87447 15.921 8.73001 15.79L3.23001 10.54C3.15742 10.47 3.09968 10.3862 3.06025 10.2934C3.02081 10.2006 3.00049 10.1008 3.00049 10C3.00049 9.89919 3.02081 9.79941 3.06025 9.70663C3.09968 9.61385 3.15742 9.52997 3.23001 9.46001L8.73001 4.21001C8.8002 4.13826 8.88408 4.08133 8.97668 4.04259C9.06928 4.00386 9.16871 3.9841 9.26908 3.9845C9.36945 3.9849 9.46873 4.00544 9.56101 4.04491C9.6533 4.08437 9.73673 4.14196 9.80635 4.21426C9.87598 4.28657 9.93038 4.37211 9.96634 4.46582C10.0023 4.55953 10.0191 4.65951 10.0157 4.75982C10.0123 4.86014 9.98881 4.95875 9.9466 5.04983C9.9044 5.1409 9.84435 5.22257 9.77001 5.29001L5.61201 9.25001L16.25 9.25001C16.4489 9.25001 16.6397 9.32902 16.7803 9.46968C16.921 9.61033 17 9.80109 17 10Z"
                fill="black"
              />
            </svg>
          </button>
        </v-col>
        <v-col>
          <h2>Фильтр</h2>
        </v-col>
        <v-col>
          <v-btn color="#FF8351" variant="text" @click="reset">
            Сбросить
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="filter__fields flex column">
      <div class="filter__location mb-6">
        <h2 class="mb-4">Расположение</h2>
        <!-- <v-autocomplete
          class="mb-4"
          :items="this.$store.state.citys"
          v-model="this.$store.state.filterOptions.city"
          :disabled="loading"
          clearable
          item-title="name"
          item-value="id"
          color="#FF8351"
          label="Город"
          variant="outlined"
          prepend-inner-icon="mdi-map-marker-outline"
          hide-details
        ></v-autocomplete>-->
        <v-autocomplete
          class="mb-4"
          :items="this.$store.state.districts"
          v-model="this.$store.state.filterOptions.district"
          :disabled="loading"
          clearable
          chips
          color="#FF8351"
          multiple
          item-title="name"
          item-value="id"
          label="Район(ы)"
          variant="outlined"
          prepend-inner-icon="mdi-map-marker-outline"
          hide-details
        ></v-autocomplete>
        <v-autocomplete
          class="mb-4"
          :items="this.$store.state.streets"
          v-model="this.$store.state.filterOptions.streets"
          :disabled="loading"
          clearable
          chips
          color="#FF8351"
          multiple
          item-title="name"
          item-value="id"
          label="Улица(ы)"
          variant="outlined"
          prepend-inner-icon="mdi-map-marker-outline"
          hide-details
        ></v-autocomplete>
        <!-- <v-autocomplete
          :items="this.$store.state.metro"
          v-model="this.$store.state.filterOptions.metro"
          :disabled="loading"
          clearable
          chips
          color="#FF8351"
          multiple
          item-title="name"
          item-value="id"
          label="Метро"
          variant="outlined"
          prepend-inner-icon="mdi-map-marker-outline"
          hide-details
        ></v-autocomplete>-->
      </div>
      <div class="filter__options mb-6">
        <!-- <div class="type__obj mb-5">
          <h2 class="mb-4">Тип объекта</h2>
           <v-btn
            class="btn_type_obj rounded-xl mr-3 mb-3"
            :disabled="loading"
            variant="outlined"
            @click="
              this.$store.state.filterOptions.isHouse =
                !this.$store.state.filterOptions.isHouse
            "
            :color="this.$store.state.filterOptions.isHouse ? '#FF8351' : ''"
            >Дом, дача</v-btn
          >
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isPlot =
                !this.$store.state.filterOptions.isPlot
            "
            :color="this.$store.state.filterOptions.isPlot ? '#FF8351' : ''"
            >Участок</v-btn
          >
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isApart =
                !this.$store.state.filterOptions.isApart
            "
            :color="this.$store.state.filterOptions.isApart ? '#FF8351' : ''"
            >Апартаменты</v-btn
          >
           <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isTownHouse =
                !this.$store.state.filterOptions.isTownHouse
            "
            :color="
              this.$store.state.filterOptions.isTownHouse ? '#FF8351' : ''
            "
            >Таунхаус</v-btn
          >
        </div>-->
        <!--  <div class="other__parametrs mb-5">
          <h2 class="mb-4">Дополнительные параметры</h2>
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isSecondary =
                !this.$store.state.filterOptions.isSecondary
            "
            :color="
              this.$store.state.filterOptions.isSecondary ? '#FF8351' : ''
            "
            >Вторичка</v-btn
          >
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isTerrace =
                !this.$store.state.filterOptions.isTerrace
            "
            :color="this.$store.state.filterOptions.isTerrace ? '#FF8351' : ''"
            >Терраса</v-btn
          >
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isNewBuild =
                !this.$store.state.filterOptions.isNewBuild
            "
            :color="this.$store.state.filterOptions.isNewBuild ? '#FF8351' : ''"
            >Новостройка</v-btn
          >
        </div>-->
        <div class="otc">
          <h2 class="mb-4">Отделка</h2>
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isOtc =
                !this.$store.state.filterOptions.isOtc
            "
            :color="this.$store.state.filterOptions.isOtc ? '#FF8351' : ''"
            >С отделкой</v-btn
          >
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isNotOtc =
                !this.$store.state.filterOptions.isNotOtc
            "
            :color="this.$store.state.filterOptions.isNotOtc ? '#FF8351' : ''"
            >Без отделки</v-btn
          >
          <v-btn
            class="rounded-xl mr-3 mb-3"
            variant="outlined"
            :disabled="loading"
            @click="
              this.$store.state.filterOptions.isWhitebox =
                !this.$store.state.filterOptions.isWhitebox
            "
            :color="this.$store.state.filterOptions.isWhitebox ? '#FF8351' : ''"
            >Whitebox</v-btn
          >
        </div>
      </div>
      <div class="filter__rooms mb-6">
        <h2 class="mb-4">Комнаты</h2>
        <v-btn
          elevation="0"
          icon="mdi-numeric-1"
          class="rounded-circle mr-3 mb-3"
          :disabled="loading"
          @click="
            this.$store.state.filterOptions.rooms.one =
              !this.$store.state.filterOptions.rooms.one
          "
          :color="
            this.$store.state.filterOptions.rooms.one ? '#FF8351' : '#F4F6F9'
          "
          >1</v-btn
        >
        <v-btn
          elevation="0"
          icon="mdi-numeric-2"
          class="rounded-circle mr-3 mb-3"
          :disabled="loading"
          @click="
            this.$store.state.filterOptions.rooms.two =
              !this.$store.state.filterOptions.rooms.two
          "
          :color="
            this.$store.state.filterOptions.rooms.two ? '#FF8351' : '#F4F6F9'
          "
          >2</v-btn
        >
        <v-btn
          elevation="0"
          icon="mdi-numeric-3"
          class="rounded-circle mr-3 mb-3"
          :disabled="loading"
          @click="
            this.$store.state.filterOptions.rooms.three =
              !this.$store.state.filterOptions.rooms.three
          "
          :color="
            this.$store.state.filterOptions.rooms.three ? '#FF8351' : '#F4F6F9'
          "
          >3</v-btn
        >
        <v-btn
          elevation="0"
          icon="mdi-numeric-4"
          class="rounded-circle mr-3 mb-3"
          :disabled="loading"
          @click="
            this.$store.state.filterOptions.rooms.four =
              !this.$store.state.filterOptions.rooms.four
          "
          :color="
            this.$store.state.filterOptions.rooms.four ? '#FF8351' : '#F4F6F9'
          "
          >4</v-btn
        >
        <v-btn
          elevation="0"
          icon="mdi-numeric-5"
          class="rounded-circle mr-3 mb-3"
          :disabled="loading"
          @click="
            this.$store.state.filterOptions.rooms.five =
              !this.$store.state.filterOptions.rooms.five
          "
          :color="
            this.$store.state.filterOptions.rooms.five ? '#FF8351' : '#F4F6F9'
          "
          >5</v-btn
        >
        <v-btn
          elevation="0"
          icon="mdi-numeric-6"
          class="rounded-circle mr-3 mb-3"
          :disabled="loading"
          @click="
            this.$store.state.filterOptions.rooms.six =
              !this.$store.state.filterOptions.rooms.six
          "
          :color="
            this.$store.state.filterOptions.rooms.six ? '#FF8351' : '#F4F6F9'
          "
          >6+</v-btn
        >
      </div>
      <div class="filter__price mb-6">
        <h2 class="mb-4">Цена</h2>
        <v-text-field
          v-model="this.$store.state.filterOptions.price"
          :disabled="loading"
          variant="outlined"
          label="За всё"
          hide-details
          @click="priceModal = !priceModal"
          color="#FF8351"
        ></v-text-field>
        <v-dialog v-model="priceModal" width="100%" class="price__modal">
          <v-card class="price__modal__card pa-6 rounded-xl">
            <div class="price__modal__header flex mb-5">
              <h2 style="align-self: center">Цена</h2>
              <div class="flex">
                <v-btn
                  class="rounded-circle mr-3"
                  elevation="0"
                  icon="mdi-numeric-1"
                  @click="this.$store.state.filterOptions.currency = '₽'"
                  :color="
                    this.$store.state.filterOptions.currency == '₽'
                      ? '#FF8351'
                      : '#F4F6F9'
                  "
                  >₽</v-btn
                >
                <v-btn
                  class="rounded-circle mr-3"
                  elevation="0"
                  icon="mdi-numeric-1"
                  @click="this.$store.state.filterOptions.currency = '$'"
                  :color="
                    this.$store.state.filterOptions.currency == '$'
                      ? '#FF8351'
                      : '#F4F6F9'
                  "
                  >$</v-btn
                >
                <v-btn
                  class="rounded-circle mr-3"
                  elevation="0"
                  icon="mdi-numeric-1"
                  @click="this.$store.state.filterOptions.currency = '€'"
                  :color="
                    this.$store.state.filterOptions.currency == '€'
                      ? '#FF8351'
                      : '#F4F6F9'
                  "
                  >€</v-btn
                >
              </div>
            </div>
            <div class="price__modal__type mb-5 rounded-xl pa-2 flex">
              <v-btn
                width="49%"
                :color="
                  this.$store.state.filterOptions.priceType
                    ? '#474849'
                    : '#f4f6f9'
                "
                class="rounded-xl"
                elevation="0"
                @click="this.$store.state.filterOptions.priceType = true"
                >За все</v-btn
              >
              <v-btn
                width="49%"
                class="rounded-xl"
                elevation="0"
                :color="
                  !this.$store.state.filterOptions.priceType
                    ? '#474849'
                    : '#f4f6f9'
                "
                @click="this.$store.state.filterOptions.priceType = false"
                >За метр</v-btn
              >
            </div>
            <div class="price__modal__form mb-5">
              <v-text-field
                class="mb-3"
                v-model="this.$store.state.filterOptions.priceFrom"
                clearable
                color="#FF8351"
                label="от"
                variant="outlined"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="this.$store.state.filterOptions.priceTo"
                clearable
                color="#FF8351"
                label="до"
                variant="outlined"
                hide-details
              ></v-text-field>
            </div>
            <div class="price__modal__actions flex justify-end">
              <v-btn variant="text" @click="priceModal = false">Отмена</v-btn>
              <v-btn variant="text" color="#FF8351" @click="getPriceFilter"
                >Ок</v-btn
              >
            </div>
          </v-card>
        </v-dialog>
      </div>
      <div class="filter__square mb-6">
        <h2 class="mb-4">Площадь, м²</h2>
        <v-text-field
          v-model="this.$store.state.filterOptions.square"
          :disabled="loading"
          variant="outlined"
          label="Общая,  м²"
          hide-details
          @click="squareModal = !squareModal"
          color="#FF8351"
        ></v-text-field>
        <v-dialog v-model="squareModal" width="100%" class="price__modal">
          <v-card class="square__modal__card pa-6 rounded-xl">
            <div class="price__modal__header flex mb-5">
              <h2 style="align-self: center">Площадь</h2>
            </div>
            <div class="square__modal__form mb-5">
              <v-text-field
                class="mb-3"
                v-model="this.$store.state.filterOptions.squareFrom"
                clearable
                color="#FF8351"
                label="от"
                variant="outlined"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="this.$store.state.filterOptions.squareTo"
                clearable
                color="#FF8351"
                label="до"
                variant="outlined"
                hide-details
              ></v-text-field>
            </div>
            <div class="square__modal__actions flex justify-end">
              <v-btn variant="text" @click="squareModal = false">Отмена</v-btn>
              <v-btn variant="text" color="#FF8351" @click="getSquareFilter"
                >Ок</v-btn
              >
            </div>
          </v-card>
        </v-dialog>
      </div>
      <div class="filter__location">
        <h2 class="mb-4">ЖК</h2>
        <v-autocomplete
          class="mb-4"
          :items="this.$store.state.residences"
          v-model="this.$store.state.filterOptions.residence"
          :disabled="loading"
          clearable
          color="#FF8351"
          label="ЖК"
          item-title="jk_name"
          item-value="id"
          multiple
          chips
          variant="outlined"
          hide-details
        ></v-autocomplete>
        <!-- <v-select
          class="mb-4"
          v-model="this.$store.state.filterOptions.statusObj"
          :items="this.$store.state.filterOptions.statusObj"
          :disabled="loading"
          clearable
          color="#FF8351"
          label="Статус объекта"
          variant="outlined"
          hide-details
        ></v-select>-->
      </div>
      <div class="filter__text text-center">
        <p>
          Чтобы ознакомиться с нашими объектами, необходимо настроить фильтр под
          ваши требования
        </p>
      </div>
      <v-btn
        class="rounded-xl"
        color="#ff8351"
        elevation="0"
        min-height="50px"
        width="100%"
        :disabled="loading"
        :loading="loading"
        @click="getObjs"
        >Показать объекты</v-btn
      >
    </div>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  name: "Card-Filter",
  props: {
    modelValue: Boolean,
    isMap: Boolean,
  },
  emits: ["update:modelValue", "updateFilterItemsMap", "updateFilterItems"],
  data() {
    return {
      loading: false,
      priceModal: false,
      squareModal: false,
      filterItems: [],
    };
  },
  methods: {
    reset() {
      const filterOptions = {
        priceType: true,
        isHouse: false,
        isPlot: false,
        isApart: false,
        isSecondary: false,
        isNewBuild: false,
        isTerrace: false,
        isTownHouse: false,
        isWhitebox: false,
        isNotOtc: false,
        isOtc: false,
        rooms: {
          one: false,
          two: false,
          three: false,
          four: false,
          five: false,
          six: false,
        },
        price: "",
        priceTo: "",
        priceFrom: "",
        square: "",
        statusObj: [],
        selectStatus: "",
        currency: "₽",
        city: [],
        district: [],
        streets: [],
        metro: [],
      };
      this.$store.commit("filterOptionUpdate", filterOptions);
    },
    getPriceFilter() {
      const filterOptions = this.$store.state.filterOptions;
      // console.log({ filterOptions });
      filterOptions.price = `${filterOptions.currency} ${filterOptions.priceFrom} - ${filterOptions.priceTo}`;
      this.$store.commit("filterOptionUpdate", filterOptions);

      this.priceModal = false;
    },
    getSquareFilter() {
      const filterOptions = this.$store.state.filterOptions;
      filterOptions.square = `${filterOptions.squareFrom} м² - ${filterOptions.squareTo} м²`;
      this.$store.commit("filterOptionUpdate", filterOptions);
      this.squareModal = false;
    },
    async getObjs() {
      try {
        this.loading = true;
        this.$store.commit("isFilterUpdate", true);
        //  console.log("filter");
        const filterObjs = await axios.get(
          "https://lissearch.ru/backend/api/api.php",
          {
            params: {
              filter: this.$store.state.filterOptions,
            },
          }
        );
        // console.log({ filterObjs });
        const objs = [];
        for (const obj of filterObjs.data) {
          //   console.log({ obj });
          if (obj.items) {
            if (obj.items.length > 0) {
              for (const objItem of obj.items) {
                objItem.lot_name = objItem.lot_name
                  .split("Продается")[1]
                  .split(",")[0];
                objItem.dialog = false;
                objItem.selected = false;
                objItem.cian = false;
                objItem.coordinates = [obj.lon, obj.lat];
                objItem.lon = obj.lon;
                objItem.lat = obj.lat;
                //--------------------------
                objItem.fullPricexls = Number(objItem.lot_price);
                objItem.fullPrice = new Intl.NumberFormat("ru-RU").format(
                  Number(objItem.lot_price)
                );
                objItem.squarePriceXls = Number(objItem.lot_price_m2);
                objItem.squarePrice = new Intl.NumberFormat("ru-RU").format(
                  Number(objItem.squarePriceXls)
                );
                objItem.comission = objItem.comission
                  ? objItem.comission
                  : "3%";
                objItem.assigned = {
                  name: "Фамилия Имя Отчество",
                  position: "Должность",
                  avatar:
                    "https://img.freepik.com/free-photo/front-view-of-smiley-man-outdoors-in-the-city_23-2148955558.jpg",
                  phone: "",
                  email: "",
                };

                objItem.wc = objItem.wc ? objItem.wc : "0";
                objItem.lot_bedroom = objItem.lot_bedroom
                  ? objItem.lot_bedroom
                  : "0";
                objItem.currency = objItem.currency ? objItem.currency : "₽";
                objItem.status = "Новостройка";
                objItem.type = "Продажа";
                objItem.photos = objItem.lot_photo_url.split(";");
              }
              obj.dialog = false;
              obj.cardDialog = false;
              obj.coordinates = [obj.lon, obj.lat];
              obj.currency = obj.items[0].currency;
              obj.squareFrom = obj.items[0].lot_square;
              obj.squareTo = obj.items[obj.items.length - 1].lot_square;
              //сортируем массив по цене
              obj.items.sort((a, b) => {
                return a.fullPricexls - b.fullPricexls;
              });
              obj.priceFromXls = Number(obj.items[0].fullPricexls);
              obj.priceFrom = new Intl.NumberFormat("ru-RU").format(
                obj.priceFromXls
              );
              obj.priceToXls = Number(
                obj.items[obj.items.length - 1].fullPricexls
              );
              obj.priceTo = new Intl.NumberFormat("ru-RU").format(
                Number(obj.items[obj.items.length - 1].fullPricexls)
              );

              objs.push(obj);
              //console.log({ objs });
            }
          }
        }
        this.$store.commit("objsAdd", objs);
        this.loading = false;
        if (this.isMap) {
          this.$emit("updateFilterItemsMap", objs);
          this.map = false;
        } else {
          this.$emit("updateFilterItems", objs);
        }
        this.$emit("update:modelValue", false);
      } catch (error) {
        console.log({ error });
      }
    },
    swipe() {
      this.$emit("update:modelValue", false);
    },
  },
  mounted() {
    this.$store.commit("isFilterUpdate", "true");
  },
};
</script>
<style scoped>
.price__modal__header {
  justify-content: space-between;
}
.filter__container {
  padding: 15px;
}
.filter__fields {
  margin-bottom: 80px;
}
.filter__options {
  padding: 15px;
  border-radius: 15px;
  background-color: #f4f6f9;
}
.price__modal__type {
  justify-content: space-between;
  background-color: #f4f6f9;
}
.filter__text {
  padding: 20px;
  font-size: 12px;
}
</style>
