<template>
  <HomePage />
</template>

<script>
import { defineComponent } from "vue";
import HomePage from "../components/home/HomePage.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    HomePage,
  },
  data() {
    return {
    //
    };
  },
});
</script>
