<template>
  <div class="container">
    <textarea
      :value="urlCopy"
      id="custom_code"
      placeholder="here is embed code"
      readonly="readonly"
      class="displaynone"
    ></textarea>

    <v-alert
      v-model="isUrlCopy"
      closable
      variant="tonal"
      :text="`Ссылка на подборку скопирована`"
      color="#ff8351"
      style="margin-bottom: 20px"
    ></v-alert>
    <div class="header flex mb-3">
      <div class="title">Жилых комплексов {{ this.$store.state.objs.length }}</div>
      <div class="search__block flex">
        <div class="search">
          <v-text-field
            clearable
            v-model="search"
            label="Поиск"
            variant="outlined"
            hide-details
            @input="searchItems"
            @click:clear="this.filterItems = this.$store.state.objs"
          ></v-text-field>
        </div>
        <div class="filter__btn">
          <v-dialog
            z-index="10"
            v-model="filterMenu"
            transition="dialog-right-transition"
            scrollable
            fullscreen
            persistent
          >
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn
                height="100%"
                width="100%"
                class="me-2 text-none"
                style="border-radius: 12px"
                color="#FF8351"
                prepend-icon="mdi-filter-variant"
                variant="flat"
                v-bind="activatorProps"
              >
                Фильтр
              </v-btn>
            </template>
            <FilterWiev
              v-model="filterMenu"
              @updateFilterItems="onUpdateFilterItems"
            ></FilterWiev>
          </v-dialog>
        </div>
      </div>
      <div class="header__action flex">
        <v-dialog
          z-index="10"
          v-model="isMap"
          transition="dialog-right-transition"
          scrollable
          fullscreen
          persistent
        >
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn
              width="48%"
              class="text-none"
              style="border-radius: 12px"
              color="#FF8351"
              prepend-icon="mdi-map"
              variant="flat"
              v-bind="activatorProps"
            >
              Карта
            </v-btn>
          </template>
          <MapPage
            :items="filterItems"
            v-model="isMap"
            :key="filterItems.length"
          ></MapPage>
        </v-dialog>

        <v-btn
          width="48%"
          class="text-none"
          style="border-radius: 12px"
          color="#FF8351"
          prepend-icon="mdi-format-list-text"
          variant="flat"
          @click="сhangeList()"
        >
          {{ typeItems }}
        </v-btn>
      </div>
    </div>
    <div class="sort__block mb-2">
      <p>
        Сортировка: <b @click="sortMenu = !sortMenu">{{ sortType.name }}</b>
      </p>
    </div>
    <div class="map"></div>
    <div class="items__block__list flex">
      <div class="item" v-for="item in filterItems" :key="item.id">
        <v-dialog
          z-index="10"
          v-model="item.dialog"
          transition="dialog-right-transition"
          scrollable
          fullscreen
          persistent
        >
          <template v-slot:activator="{ props: activatorProps }">
            <Card v-if="!isList" :item="item" v-bind="activatorProps"></Card>
            <CardList v-else :item="item" v-bind="activatorProps"></CardList>
          </template>
          <ItemPage :items="item.items" v-model="item.dialog"></ItemPage>
        </v-dialog>
      </div>
    </div>
    <v-bottom-sheet v-model="options">
      <v-list class="rounded-t-xl" height="250">
        <v-list-subheader>Действия</v-list-subheader>
        <v-list-item
          :title="`Создать PDF`"
          :disabled="loading"
          :loading="loading"
          @click="presentationAdd('enable')"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi-view-list-outline"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          title="Сбросить подборку"
          @click="deleteSelectItems"
          :disabled="loading"
          :loading="loading"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi-trash-can-outline"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="sortMenu">
      <v-list class="rounded-t-xl" height="250">
        <v-list-subheader>Действия</v-list-subheader>
        <v-list-item
          v-for="sortItem in sortTypes"
          :key="sortItem.id"
          :title="sortItem.name"
          @click="sortItems(sortItem)"
        >
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-snackbar v-model="isPress" color="white" min-width="104%">
      <v-btn
        class="presentation__btn text-none mr-5"
        height="100%"
        width="75%"
        style="border-radius: 35px; padding: 25px; font-size: 16px"
        color="#FF8351"
        variant="flat"
        :disabled="loading"
        :loading="loading"
        @click="addSelection"
        >{{
          `Создать подборку (${this.$store.state.selectItems.length})`
        }}</v-btn
      >
      <v-btn
        class="rounded-circle mx-auto"
        style="height: 64px"
        @click="options = !options"
      >
        <svg
          width="16"
          height="4"
          viewBox="0 0 16 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.96484 3.61816C1.09473 3.61816 0.417969 2.94141 0.417969 2.07129C0.417969 1.20117 1.09473 0.524414 1.96484 0.524414C2.82422 0.524414 3.50098 1.20117 3.50098 2.07129C3.50098 2.94141 2.82422 3.61816 1.96484 3.61816ZM7.89453 3.61816C7.02441 3.61816 6.34766 2.94141 6.34766 2.07129C6.34766 1.20117 7.02441 0.524414 7.89453 0.524414C8.75391 0.524414 9.43066 1.20117 9.43066 2.07129C9.43066 2.94141 8.75391 3.61816 7.89453 3.61816ZM13.8242 3.61816C12.9541 3.61816 12.2773 2.94141 12.2773 2.07129C12.2773 1.20117 12.9541 0.524414 13.8242 0.524414C14.6836 0.524414 15.3604 1.20117 15.3604 2.07129C15.3604 2.94141 14.6836 3.61816 13.8242 3.61816Z"
            fill="#474849"
          />
        </svg>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import ItemPage from "../home/ItemPage.vue";
import CardList from "../home/CardList.vue";
import Card from "../home/Card.vue";
import FilterWiev from "../home/FilterWiev.vue";
import MapPage from "../home/CardMap.vue";
import axios from "axios";
import copy from "copy-to-clipboard";

export default {
  name: "HomePage",
  components: {
    ItemPage,
    CardList,
    Card,
    FilterWiev,
    MapPage,
  },
  emits: ["updateFilterItemsMap"],
  data() {
    return {
      isUrlCopy: false,
      urlCopy: "",
      search: "",
      loading: false,
      typeItems: "Карточки",
      sortType: { id: 2, name: "Cначала старые" },
      sortMenu: false,
      filterMenu: false,
      options: false,
      isMap: false,
      isList: true,
      sortTypes: [
        { id: 1, name: "Cначала новые" },
        { id: 2, name: "Cначала старые" },
        { id: 3, name: "Cначала дешевые" },
        { id: 4, name: "Cначала дорогие" },
      ],
      items: [],
      filterItems: [],
    };
  },
  methods: {
    async getStreets() {
      const streets = await axios.get(
        "https://lissearch.ru/backend/api/api.php",
        { params: { params: "getStreet" } }
      );
      this.$store.commit("streetsAdd", streets.data);
    },
    async getDistricts() {
      const districts = await axios.get(
        "https://lissearch.ru/backend/api/api.php",
        { params: { params: "getDistrict" } }
      );
      // console.log({ districts });
      this.$store.commit("districtsAdd", districts.data);
    },
    async getCitys() {
      const citys = await axios.get(
        "https://lissearch.ru/backend/api/api.php",
        { params: { params: "getCity" } }
      );
      // console.log({ citys });
      this.$store.commit("citysAdd", citys.data);
    },
    async getResidences() {
      const residences = await axios.get(
        "https://lissearch.ru/backend/api/api.php",
        { params: { params: "getJk" } }
      );
      // console.log({ residences });
      this.$store.commit("residencesAdd", residences.data);
    },
    async getMetro() {
      const metro = await axios.get(
        "https://lissearch.ru/backend/api/api.php",
        { params: { params: "getMetro" } }
      );
      //   console.log({ metro });
      this.$store.commit("metroAdd", metro.data);
    },
    onUpdateFilterItems(data) {
      this.filterItems = data;
    },
    async getItems() {
      let start = 0;
      let isTrue = true;
      const objs = [];
      while (isTrue) {
        const objsData = await axios.get(
          "https://lissearch.ru/backend/api/api.php",
          { params: { params: "getObjectsList", start } }
        );
        // console.log({ objsData });
        for (const obj of objsData.data.result) {
          //  console.log({ obj });
          if (obj.items) {
            if (obj.items.length > 0) {
              for (const objItem of obj.items) {
                objItem.lot_name = objItem.lot_name
                  .split("Продается")[1]
                  .split(",")[0];
                objItem.dialog = false;
                objItem.selected = false;
                objItem.cian = false;
                objItem.coordinates = [obj.lon, obj.lat];
                objItem.lon = obj.lon;
                objItem.lat = obj.lat;
                //--------------------------
                objItem.fullPricexls = Number(objItem.lot_price);
                objItem.fullPrice = new Intl.NumberFormat("ru-RU").format(
                  Number(objItem.lot_price)
                );
                objItem.squarePriceXls = Number(objItem.lot_price_m2);
                objItem.squarePrice = new Intl.NumberFormat("ru-RU").format(
                  Number(objItem.squarePriceXls)
                );
                objItem.comission = objItem.comission
                  ? objItem.comission
                  : "3%";
                objItem.assigned = {
                  name: "Фамилия Имя Отчество",
                  position: "Должность",
                  avatar:
                    "https://img.freepik.com/free-photo/front-view-of-smiley-man-outdoors-in-the-city_23-2148955558.jpg",
                  phone: "",
                  email: "",
                };

                objItem.wc = objItem.wc ? objItem.wc : "0";
                objItem.lot_bedroom = objItem.lot_bedroom
                  ? objItem.lot_bedroom
                  : "0";
                objItem.currency = objItem.currency ? objItem.currency : "₽";
                objItem.status = "Новостройка";
                objItem.type = "Продажа";
                objItem.photos = objItem.lot_photo_url.split(";");
              }
              obj.dialog = false;
              obj.cardDialog = false;
              obj.coordinates = [obj.lon, obj.lat];
              obj.currency = obj.items[0].currency;
              obj.squareFrom = obj.items[0].lot_square;
              obj.squareTo = obj.items[obj.items.length - 1].lot_square;
              //сортируем массив по цене
              obj.items.sort((a, b) => {
                return a.fullPricexls - b.fullPricexls;
              });
              obj.priceFromXls = Number(obj.items[0].fullPricexls);
              obj.priceFrom = new Intl.NumberFormat("ru-RU").format(
                obj.priceFromXls
              );
              obj.priceToXls = Number(
                obj.items[obj.items.length - 1].fullPricexls
              );
              obj.priceTo = new Intl.NumberFormat("ru-RU").format(
                Number(obj.items[obj.items.length - 1].fullPricexls)
              );
              if (!this.$store.state.isFilter) {
                objs.push(obj);
                // console.log({ objs });
                this.filterItems.push(obj);
                this.$store.commit("objsAdd", objs);
              } else {
                break;
              }
            }
          }
        }
        if (objsData.data.next) {
          start = objsData.data.next;
        } else {
          isTrue = false;
        }
      }
      //формируем массив
    },
    searchItems() {
      console.log(this.search);
      if (this.search) {
        if (this.search.length > 0) {
          console.log(this.search, "search");
          this.filterItems = this.$store.state.objs.filter((item) => {
            return (
              //название, адрес, сумма,
              item.title.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.priceTo.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.priceFrom.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
              item.address.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            );
          });
          console.log(this.filterItems);
        } else {
          this.filterItems = this.$store.state.objs;
        }
      } else {
        this.filterItems = this.$store.state.objs;
      }
    },
    sortItems(item) {
      try {
        this.sortType = item;
        switch (item.id) {
          case 1:
            // console.log(item.id, "1");
            this.filterItems.sort((a, b) => {
              return Number(b.id) - Number(a.id);
            });
            break;
          case 2:
            // console.log(item.id, "2");
            this.filterItems.sort((a, b) => {
              return Number(a.id) - Number(b.id);
            });
            break;
          case 3:
            // console.log(item.id, "3");
            this.filterItems.sort((a, b) => {
              return a.priceToXls - b.priceToXls;
            });
            break;
          case 4:
            //console.log(item.id, "4");
            this.filterItems.sort((a, b) => {
              return b.priceToXls - a.priceToXls;
            });
            break;
        }
        this.sortMenu = false;
      } catch (error) {
        console.log({ error });
      }
    },
    сhangeList() {
      this.isList = !this.isList;
      if (this.isList) this.typeItems = "Карточки";
      else this.typeItems = "Список";
    },
    deleteSelectItems() {
      try {
        for (const item of this.$store.state.selectItems) {
          item.selected = false;
        }
        this.$store.commit(
          "newSelectItems",
          this.$store.state.selectItems.filter((e) => e.selected)
        );
        this.options = false;
      } catch (error) {
        console.log({ error });
      }
    },
    async presentationAdd(type) {
      try {
        //  console.log("presentationAdd");
        this.loading = true;
        let formData = new FormData();
        for (let i = 0; i < this.$store.state.selectItems.length; i++) {
          const item = this.$store.state.selectItems[i];
          item.currencyArr = ["RUB", item.currency];
          for (const index in item) {
            formData.append(`cards[${i}][${index}]`, item[index]);
          }
          formData.append(`cards[${i}][presType]`, type);
        }

        const res = await axios.post(
          "https://lissearch.ru/backend/presentation/create_presentation.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //  console.log({ res });
        let name = `Презентация ${this.items.length} объектов.pdf`;
        if (this.items.length < 2) {
          name = `${this.items[0].name}-${this.items[0].square} m2.pdf`;
        }
        const link = document.createElement("a");
        link.href = res.data;
        link.download = name;
        link.click();
        this.options = false;
        this.loading = false;
      } catch (error) {
        console.log({ error });
      }
    },
    pause(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async addSelection() {
      this.loading = true;
      let url = "https://client.lissearch.ru?";
      const objs = this.$store.state.selectItems;
      console.log({ objs });
      for (const indexObj in objs) {
        if (indexObj == 0) {
          url += `id=${objs[indexObj].id}`;
        } else {
          url += `&id=${objs[indexObj].id}`;
        }
      }
      //  console.log({ url });
      const clickru = await axios.get("https://clck.ru/--", {
        params: { url },
      });
      // console.log({ clickru });
      const saveUrl = clickru.data;
      this.urlCopy = saveUrl;
      //const isUrlCopy = await window.navigator.clipboard.writeText(saveUrl);
      //console.log({ isUrlCopy });
      let custom_code = document.querySelector("#custom_code");
      custom_code.classList.remove("displaynone");

      custom_code.select();
      document.execCommand("copy");
      await this.pause(100);
      custom_code.classList.add("displaynone");
      console.log(custom_code);
      copy(this.urlCopy);
      this.isUrlCopy = true;
      //console.log({ saveUrl });
      this.loading = false;
    },
    getOptions() {
      try {
        this.options = true;
      } catch (error) {
        console.log({ error });
      }
    },
  },
  computed: {
    isPress() {
      return this.$store.state.selectItems.length > 0;
    },
  },
  async mounted() {
    await this.getCitys();
    await this.getDistricts();
    await this.getStreets();
    await this.getResidences();
    await this.getMetro();
    await this.getItems();
    this.filterItems = this.$store.state.objs;
  },
};
</script>
<style scoped>
.v-list-item__prepend {
  display: block;
}
</style>
