<template>
  <v-lazy
    class="card flex"
    :options="{
      threshold: 0.5,
    }"
    transition="fade-transition"
  >
    <div class="card__details flex">
      <img class="card-img" :src="item.mainPhoto.url" alt="photo" />
      <div class="description flex">
        <div class="description__block">
          <p class="title">
            {{
              item.title.length > 20
                ? `${item.title.substr(0, 19)}...`
                : item.title
            }}
          </p>
          <p class="address">
            {{
              item.address.length > 30
                ? `${item.address.substr(0, 28)}...`
                : item.address
            }}
          </p>
          <div class="price">
            <span class="full-price"
              ><b>от{{ item.currency }} {{ item.priceFrom }}</b></span
            >
            <span class="full-price">
              до {{ item.currency }} {{ item.priceTo }}</span
            >
          </div>
          <div class="price">
            <span class="full-price"
              ><b>от {{ item.squareFrom }} м²</b></span
            >

            <span class="full-price"> до {{ item.squareTo }} м²</span>
          </div>
        </div>
        <div class="id-block flex">
          <div class="item__type pl-3 pr-3">
            {{ item.items.length }} объектов
          </div>
          <!-- <b class="ident">ID: {{ item.id }}</b>-->
        </div>
      </div>
    </div>
  </v-lazy>
</template>

<script>
export default {
  name: "Card-Component",
  props: {
    item: Object,
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      selected: false,
    };
  },
  watch: {
    selected(val) {
      this.$emit("update:modelValue", val);
    },
  },
};
</script>

<style scoped>
.full-price {
  font-size: 12px;
}
.gray-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #878787;
}
.card {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
}
.card__block {
  flex-direction: row;
}
.card__details {
  width: 100%;
}
.card-img {
  max-height: 152px;
  width: 35%;
  border-radius: 20px;
  object-fit: cover;
}
.description {
  flex-direction: column;
  padding: 12px;
}
.characters-block {
  flex-wrap: wrap;
  margin-block: 10px;
}
.id-block {
  justify-content: space-between;
}
.title__block {
  justify-content: space-between;
  margin-bottom: 10px;
}
.title {
  font-size: 16px;
}
.price {
  font-size: 12px;
}
.address {
  font-size: 12px;
  font-weight: 400;
}
.characters {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.characters__text {
  margin-right: 3px;
}
.characters__icon {
  margin-right: 3px;
}
.status-block {
  flex-direction: row;
}
.item__type {
  padding: 0 4px;
  border: 1px solid #f4f6f9;
  border-radius: 6px;
  background-color: #f4f6f9;
}
.checkbox_block {
  position: absolute;
  top: 15px;
  right: 0;
}
.checkbox_label {
  padding: 0;
  margin: 0;
}
</style>
